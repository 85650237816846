import React, {createContext, ReactNode, useState} from 'react';
import {Snackbar} from "@material-ui/core";
import Slide from '@material-ui/core/Slide';
import {TransitionProps} from "@material-ui/core/transitions";

interface SnackbarState {
  createSnackbar: (value: string) => void
}


export const SnackbarContext = createContext<SnackbarState>({
  createSnackbar: () => {}
})

function SlideTransition(props: TransitionProps) {
  return <Slide {...props} direction="down" />;
}

function SnackbarProvider({children}: {children: ReactNode}) {

  const [state, setState] = useState<{message: string, open: boolean}>({
    message: '',
    open: false
  })

  return (
    <SnackbarContext.Provider value={{
      createSnackbar: value => setState({message: value, open: true})
    }}>
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        open={state.open}
        autoHideDuration={3000}
        onClose={() => setState({message: '', open: false})}
        message={state.message}
        TransitionComponent={SlideTransition}
      />
      {children}
    </SnackbarContext.Provider>
  )
}

export default SnackbarProvider

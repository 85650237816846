import React, {useContext} from 'react';
import './App.css';
import Profile from "./components/Profile";
import {Switch, Route, Redirect} from "react-router-dom";
import Home from "./components/Home";
import PhotoWithNav from "./components/PhotoWithNav";
import {FirebaseContext} from "./firebase/FirebaseContextProvider";
import {Stage, StageContext} from "./components/StageProvider";
import About from "./components/About";

function App() {

  const {user, handle} = useContext(FirebaseContext)
  const {stage} = useContext(StageContext)

  return (
    <Switch>
      <Route exact path='/'>
        {user && handle.value && stage === Stage.loggedIn ? <Redirect to={handle.value} /> : <Home />}
      </Route>
      <Route exact path='/about'>
        <About />
      </Route>
      <Route path='/:userHandle/:imageId'>
        <PhotoWithNav />
      </Route>
      <Route path='/:userHandle'>
        <Profile />
      </Route>
    </Switch>
  )
}

export default App;

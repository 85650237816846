import {useEffect, useState} from "react";
import Dexie from 'dexie';
import firebase from "firebase/app";

function loadImage(imageUrl: string, onprogress: (progress: number) => void): {promise: Promise<Blob>, cancel: () => void} {
  const xhr = new XMLHttpRequest();
  const promise = new Promise<Blob>((resolve, reject) => {
    let notifiedNotComputable = false;

    xhr.open('GET', imageUrl, true);
    xhr.responseType = 'arraybuffer';

    xhr.onprogress = function(ev) {
      if (ev.lengthComputable) {
        onprogress((ev.loaded / ev.total) * 100);
      } else {
        if (!notifiedNotComputable) {
          notifiedNotComputable = true;
          onprogress(-1);
        }
      }
    }

    xhr.onloadend = function() {
      if (!xhr.status.toString().match(/^2/)) {
        reject(xhr);
      } else {
        if (!notifiedNotComputable) {
          onprogress(100);
        }

        const options: {type?: string} = {}
        const headers = xhr.getAllResponseHeaders();
        const m = headers.match(/^Content-Type:\s*(.*?)$/mi);

        if (m && m[1]) {
          options.type = m[1];
        }

        const blob = new Blob([this.response], options);
        resolve(blob)
      }
    }
    xhr.send();
  });

  return {
    promise,
    cancel: () => {
      xhr.abort()
    }
  }
}

interface Image {
  id: string,
  blob: Blob,
  timestamp: number
}

class ImageDB extends Dexie {
  public images: Dexie.Table<Image, string>

  public constructor() {
    super("ImageDB");
    this.version(1).stores({
      images: "id"
    })
    this.images = this.table("images")
  }
}

function useImageWithProgress(url: string | null, firebase: firebase.app.App | null) {
  const [progress, setProgress] = useState<number>()
  const [src, setSrc] = useState<string>()

  useEffect(() => {
    setSrc('')
    setProgress(0)
    if (firebase && url) {
      const db = new ImageDB()
      let cancelled = false;
      let blobUrl = '';
      let cancelXhr: () => void = () => {}

      const storeInDB = (image: Image): Promise<void> => {
        return db.transaction('rw', db.images, async () => {
/*
          await db.images.filter(obj => obj.timestamp < Date.now() - 1000 * 3600 * 24).delete()
*/
          await db.images.put(image).catch(() => console.log("error caching image"))
        })
      }

      const readFromDB = (url: string): Promise<Image | undefined> => {
        return db.transaction('r', db.images, async () => {
          return db.images.get(url)
        })
      }

      const processImage = (image: Image) => {
        if (!cancelled) {
          setProgress(100)
          blobUrl = URL.createObjectURL(image.blob)
          setSrc(blobUrl)
        }
      }

      (async function() {
        const image = await readFromDB(url)
        if (image) {
          processImage(image)
        } else if (!cancelled) {
          const downloadUrl = await firebase.storage().ref(url).getDownloadURL()
          if (downloadUrl) {
            const {promise, cancel} = loadImage(downloadUrl, p => {
              if (!cancelled) {
                setProgress(p)
              }
            })
            cancelXhr = () => {
              cancel()
            }
            try {
              const blob = await promise
              const image: Image = {id: url, blob, timestamp: Date.now()}
              await storeInDB(image)
              processImage(image)
            } catch (e) {
              if (!cancelled) {
                throw e
              }
            }
          }
        }
      })()

      return () => {
        cancelled = true
        cancelXhr()
        if (blobUrl) {
          URL.revokeObjectURL(blobUrl)
        }
      }
    }
  }, [url, firebase])

  return {
    src,
    progress
  }
}

export default useImageWithProgress

import React, {useContext} from 'react'
import {Link, useLocation} from "react-router-dom";
import './Appbar.css'
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {IconButton} from "@material-ui/core";
import ShareIcon from '@material-ui/icons/Share';
import {ShareDialogContext} from "./ShareDialogProvider";
import SystemUpdateIcon from '@material-ui/icons/SystemUpdate';
import {InstallPromptContext} from "../hooks/InstallPromptProvider";
import {FirebaseContext} from "../firebase/FirebaseContextProvider";
import SettingsIcon from '@material-ui/icons/Settings';
import RssFeedIcon from '@material-ui/icons/RssFeed';
import {ThemeContext} from "../hooks/ThemeProvider";

interface AppbarProps {
  mobileFormat: boolean
  userHandle: string
  withIcons: boolean
  alwaysDisplayTitle?: boolean
}

const Appbar = ({mobileFormat, userHandle, withIcons, alwaysDisplayTitle}: AppbarProps) => {

  const {openShareDialog} = useContext(ShareDialogContext)
  const {user, handle} = useContext(FirebaseContext)
  const location = useLocation()
  const {installable, handleInstallClick} = useContext(InstallPromptContext)
  const {darkTheme} = useContext(ThemeContext)

  const iconColor = darkTheme ? "#fff" : "#000"

  return (
    <div className='navbar-container' style={{
      backgroundColor: darkTheme ? "#000" : "#fff",
      borderBottomColor: darkTheme ? "#444" : "#ddd"
    }}>
      <div className='navbar-sizing'>
        {(!mobileFormat || alwaysDisplayTitle) && <div className='navbar-content' style={{
          justifyContent: 'center',
        }}>
          <div style={{
            fontSize: '20px',
            fontFamily: "'Raleway', sans-serif",
            zIndex: 10,
            color: iconColor
          }}>
            {userHandle}
          </div>
        </div>}
        <div className='navbar-content' style={{
          justifyContent: 'space-between',
          paddingLeft: mobileFormat ? '0.2rem' : '1.5rem',
          paddingRight: mobileFormat ? '0.2rem' : '1.5rem'
        }}>
          <Link to={user && handle.value === userHandle ? '#' : '/'}>
            <IconButton disabled={!!(user && handle.value === userHandle)}>
              <ArrowBackIcon style={{
                color: user && handle.value === userHandle ? 'gray' : iconColor
              }}/>
            </IconButton>
          </Link>
          <div style={{
            display: 'flex'
          }}>
            {installable && <IconButton onClick={handleInstallClick}>
              <SystemUpdateIcon style={{
                color: iconColor
              }}/>
            </IconButton>}

            {withIcons && <React.Fragment>
              {user && handle.value === userHandle &&
              <Link to={'/settings'}>
                <IconButton>
                  <SettingsIcon style={{
                    color: iconColor
                  }}/>
                </IconButton>
              </Link>
              }
              {userHandle !== 'settings' && <React.Fragment>
                <IconButton onClick={() => openShareDialog(`https://andagram.com${location.pathname}`)}>
                  <ShareIcon style={{
                    color: iconColor
                  }}/>
                </IconButton>
                <IconButton onClick={() => openShareDialog(`https://andagram.com${location.pathname}/feed`)}>
                  <RssFeedIcon style={{
                    color: iconColor
                  }}/>
                </IconButton>
              </React.Fragment>
              }
            </React.Fragment>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Appbar

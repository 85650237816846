import {createContext, ReactNode, useContext, useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {FirebaseContext} from "../firebase/FirebaseContextProvider";

interface State {
  role: "guest" | "host" | null,
  presenter: string | null,
  updateSlide: (slide: string) => Promise<void>
}

export const PresenterContext = createContext<State>({
  role: null,
  presenter: null,
  updateSlide: async () => {}
})

function PresenterProvider({children}: {children: ReactNode}) {
  const location = useLocation()
  const [presenter, setPresenter] = useState<string | null>(null)
  const {firebase, handle, user} = useContext(FirebaseContext)
  const [redirect, setRedirect] = useState<{slide: string, presenter: string} | null>(null)
  const history = useHistory()

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    setPresenter(params.get('presenter'))
  }, [location])

  useEffect(() => {
    let unsubscribe: any = null
    let cancelled = false
    if (presenter && firebase) {
      firebase.firestore().collection("handles")
        .doc(presenter).get().then(doc => {
          if (doc.exists && !cancelled) {
            const uid: string = doc.get("uid")
            if (uid) {
              unsubscribe = firebase.firestore().collection("users")
                .doc(uid).onSnapshot(next => {
                  if (next.exists && !cancelled) {
                    const slide: string = next.get("slide")
                    if (slide) {
                      setRedirect({
                        slide,
                        presenter
                      })
                    }
                  }
                })
            }
          }
        })
    }

    return () => {
      if (unsubscribe) {
        cancelled = true
        unsubscribe()
      }
    }
  }, [presenter, firebase])

  useEffect(() => {
    if (redirect && presenter) {
      history.push({
        pathname: `${redirect.slide}`,
        search: `?presenter=${presenter}`
      })
    }
  }, [redirect, history, presenter])

  return <PresenterContext.Provider value={{
    role: presenter ? presenter === handle.value ? "host" : "guest" : null,
    presenter,
    updateSlide: async (slide: string) => {
      if (firebase && user) {
        await firebase.firestore().collection("users").doc(user.uid).update({
          slide
        })
      }
    }
  }}>
    {children}
  </PresenterContext.Provider>

}

export default PresenterProvider

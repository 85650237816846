import {createContext, ReactNode} from "react";
import useInstallPrompt from "./useInstallPrompt";

interface State {
  installable: boolean,
  handleInstallClick: () => void
}

export const InstallPromptContext = createContext<State>({
  installable: false,
  handleInstallClick: () => {}
})

function InstallPromptProvider({children}: {children: ReactNode}) {

  const {installable, handleInstallClick} = useInstallPrompt()

  return (
    <InstallPromptContext.Provider value={{installable, handleInstallClick}}>
      {children}
    </InstallPromptContext.Provider>
  )
}

export default InstallPromptProvider


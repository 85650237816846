import React, {ReactNode} from 'react'
import {IconButton} from "@material-ui/core";
import {Link} from "react-router-dom";

interface BottomNavButtonProps {
  icon: ReactNode;
  disabled: boolean;
  to?: string;
  onClick?: () => void;
  ariaLabel: string;
}

const BottomNavButton = ({icon, disabled, to, ariaLabel, onClick}: BottomNavButtonProps) => {
  if (disabled || (!to && onClick)) {
    return (
      <IconButton aria-label={ariaLabel} disabled={disabled} onClick={onClick}>
        {icon}
      </IconButton>
  )
  } else if (to) {
    return (
      <Link to={to}>
        <IconButton aria-label={ariaLabel}>
          {icon}
        </IconButton>
      </Link>
    )
  }
  return (<React.Fragment />)
}

export default BottomNavButton

import React, {createContext, ReactNode, useState} from 'react';

export enum Stage {
  spinner,
  signUp,
  emailSent,
  emailConfirmation,
  loggedIn
}

interface State {
  stage: Stage,
  setStage: (stage: Stage) => void
}

export const StageContext = createContext<State>({
  stage: Stage.spinner,
  setStage: () => {}
})


function StageProvider({children}: {children: ReactNode}) {
  const [stage, setStage] = useState<Stage>(Stage.spinner)

  return (
    <StageContext.Provider value={{stage, setStage}}>
      {children}
    </StageContext.Provider>
  )
}

export default StageProvider

import {useEffect, useState} from "react";

interface PromptEvent {
  preventDefault: () => void
  prompt: () => void
  userChoice: Promise<{
    outcome: string
  }>
}

function useInstallPrompt() {

  const [prompt, setPrompt] = useState<PromptEvent | null>(null)
  const [installable, setInstallable] = useState<boolean>(false)

  useEffect(() => {
    const beforeInstallListener = (e: PromptEvent) => {
      e.preventDefault()
      setPrompt(e)
      setInstallable(true)
    }
    const appInstalledListener = () => {
      console.log("installed")
    }
    // @ts-ignore
    window.addEventListener("beforeinstallprompt", beforeInstallListener)
    window.addEventListener("appinstalled", appInstalledListener)
    return () => {
      // @ts-ignore
      window.removeEventListener("beforeinstallprompt", beforeInstallListener)
      window.removeEventListener("appinstalled", appInstalledListener)
    }
  }, [])

  const handleInstallClick = () => {
    if (prompt) {
      setInstallable(false)
      prompt.prompt()
      prompt.userChoice.then(choiceResult => {
        if (choiceResult.outcome === 'accepted') {
          console.log('user accepted install prompt')
        } else {
          console.log('user dismissed')
        }
      })
    }
  }

  return {
    installable,
    handleInstallClick
  }
}

export default useInstallPrompt

import React, {createContext, ReactNode, useContext, useState} from 'react';
import {
  createMuiTheme,
  Dialog,
  IconButton,
  MuiThemeProvider
} from "@material-ui/core";
import LinkIcon from '@material-ui/icons/Link';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {SnackbarContext} from "./SnackbarProvider";


interface ShareDialogState {
  openShareDialog: (value: string) => void,
}

export const ShareDialogContext = createContext<ShareDialogState>({
  openShareDialog: () => {},
})

const theme = createMuiTheme({
  palette: {
    type: 'dark',
  }
});

function ShareDialogProvider({children}: {children: ReactNode}) {
  const [open, setOpen] = useState<boolean>(false)
  const [url, setUrl] = useState<string>('')
  const {createSnackbar} = useContext(SnackbarContext)

  return (
    <ShareDialogContext.Provider value={{
      openShareDialog: (value: string) => {
        setUrl(value)
        setOpen(true)
      }
    }}>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <div style={{
          padding: '1.5rem'
        }}>
          <MuiThemeProvider theme={theme}>
            <CopyToClipboard text={url} onCopy={() => {
              createSnackbar('Link copied!')
              setOpen(false)
            }}>
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                cursor: 'pointer'
              }}>
                <IconButton style={{
                  backgroundColor: 'black',
                  width: 50,
                  height: 50
                }}>
                  <LinkIcon/>
                </IconButton>
                <div style={{
                  marginTop: 10
                }}>
                  Copy link
                </div>
              </div>
            </CopyToClipboard>
          </MuiThemeProvider>
        </div>
      </Dialog>
      {children}
    </ShareDialogContext.Provider>
  )
}

export default ShareDialogProvider

import React, {useEffect} from 'react';
import Appbar from "./Appbar";
import "./About.css"
import {useTrail, animated} from "react-spring";

function About() {

  const [trail, set] = useTrail<{opacity: number, left: number, leftOut: number, opacityOut: number}>(3, () => ({opacity: 0, left: -100, leftOut: 0, opacityOut: 1}))

  const contentListFunction = (opacity: number): {text: JSX.Element, src: string, alt: string}[] => ([
    {text: <animated.p style={{opacity}}>Andagram allows you to share photos in high quality and in any proportions. They will look great also on the larger screen.</animated.p>, src: "photo-hq.svg", alt: "high-quality"},
    {text: <animated.p style={{opacity}}>Andagram has built-in presenter mode. Share the story behind your photos.</animated.p>, src: "photo-sharing.svg", alt: "presenter"},
    {text: <animated.p style={{opacity}}>Andagram lets you follow creators using your RSS reader of choice. No ads and no account needed. <a href='https://zapier.com/blog/how-to-use-rss-feeds/' target='_blank' rel='noreferrer'>Click to learn more about RSS.</a></animated.p>, src: "serenity.svg", alt: "rss"}
  ])

  useEffect(() => {
    set({
      opacity: 1,
      left: 0,
      opacityOut: 0,
      leftOut: -100
    })
  }, [set])

  return (
    <React.Fragment>
      <Appbar mobileFormat={true} userHandle={"About"} withIcons={false} alwaysDisplayTitle />
      <div className='about-container'>
        {trail.map(({left, opacity, leftOut, opacityOut}, i) => {
          const contentList = contentListFunction(opacity)
            return (<div key={`trail-${i}`} className='about-content'>
              {i % 2 === 0 ?
                <React.Fragment>
                  <animated.div>
                    <animated.img style={{left, opacity}} src={contentList[i].src} alt={contentList[i].alt}/>
                  </animated.div>
                  {contentList[i].text}
                </React.Fragment>
                :
                <React.Fragment>
                  {contentList[i].text}
                  <div>
                    <animated.img style={{right: left, opacity}} src={contentList[i].src} alt={contentList[i].alt}/>
                  </div>
                </React.Fragment>
              }
            </div>)
        })}
      </div>
    </React.Fragment>
  )
}

export default About

import React, {ChangeEvent, useContext, useEffect, useRef, useState} from 'react'
import {CircularProgress} from "@material-ui/core";
import "./MiniatureAddPhoto.css"
import AddCircleIcon from '@material-ui/icons/AddCircle';
import useMeasure from "react-use-measure";
import {FirebaseContext} from "../firebase/FirebaseContextProvider";
import firebase from "firebase/app";
import { v4 as uuidv4 } from 'uuid';
import {ResizeObserver} from "@juggle/resize-observer";
import {SnackbarContext} from "./SnackbarProvider";
import heic2any from "heic2any";
// @ts-ignore
const reduce = window.ImageBlobReduce()


// @ts-ignore
reduce._calculate_size = function(env: any) {
  //const shorterSide = Math.min(env.image.width, env.image.height)
  //let scaleFactor = env.opts.max / shorterSide
  //if (scaleFactor < 1) {
  //  scaleFactor = 1
  //}
  //env.transform_width = Math.round(env.image.width * scaleFactor)
  //env.transform_height = Math.round(env.image.height * scaleFactor)

  env.opts.originalWidth(env.image.width)
  env.opts.originalHeight(env.image.height)
  const shorterSide = Math.min(env.image.width, env.image.height)
  let scale_factor = env.opts.max / shorterSide
  //var scale_factor = env.opts.max / Math.max(env.image.width, env.image.height);

  if (scale_factor > 1) scale_factor = 1;

  env.transform_width = Math.max(Math.round(env.image.width * scale_factor), 1);
  env.transform_height = Math.max(Math.round(env.image.height * scale_factor), 1);

  // Info for user plugins, to check if scaling applied
  env.scale_factor = scale_factor;

  return Promise.resolve(env);
}

const STATE_CHANGED = firebase.storage.TaskEvent.STATE_CHANGED

interface MiniatureAddPhotoProps {
  focused: boolean,
  reset: () => void
}

function MiniatureAddPhoto({focused, reset}: MiniatureAddPhotoProps) {

  const [ref, bounds] = useMeasure({polyfill: ResizeObserver})
  const {firebase, user} = useContext(FirebaseContext)
  const inputFile = useRef<HTMLInputElement | null>(null);
  const [uploadState, setUploadState] = useState<{
    previewSrc: string,
    processing: boolean,
    progress: number,
  }>({
    previewSrc: '',
    processing: false,
    progress: 0
  })
  const [uploadTask, setUploadTask] = useState<{
    ref: string,
    blob: Blob,
    userId: string,
    imageId: string
  } | null>(null)
  const {createSnackbar} = useContext(SnackbarContext)

  useEffect(() => {
    let cancelled = false;
    if (uploadTask && firebase) {
      const unsubscribe = firebase.storage().ref(uploadTask.ref).put(uploadTask.blob)
        .on(STATE_CHANGED, {
          next: snapshot => {
            const percent = snapshot.bytesTransferred / snapshot.totalBytes * 100
            setUploadState(prevState => ({...prevState, progress: percent}))
          },
          complete: () => {
            firebase.firestore()
              .collection("users")
              .doc(uploadTask.userId)
              .collection("pictures")
              .doc(uploadTask.imageId)
              .update({
                uploaded: true
              }).then(() => {
                if (!cancelled) {
                  setUploadTask(null)
                  setUploadState({
                    previewSrc: '',
                    processing: false,
                    progress: 0
                  })
                  reset()
                  //createSnackbar('Upload complete! Image will appear shortly')
                }
              })
          }
        })
      return () => {
        cancelled = true
        unsubscribe()
      }
    }
  }, [uploadTask, firebase, reset])

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const fileList: FileList | null = event.target.files
    if (fileList) {
      const file: File = fileList[0];
      if (file && firebase && user) {
        const fnameSplit = file.name.split('.')
        if (fnameSplit.length > 1) {
          const extension = fnameSplit[fnameSplit.length - 1]
          const isHeic = ["heif", "heifs", "heic", "heics", "avci", "avcs", "avif", "avifs"].indexOf(extension.toLowerCase()) > 0;
          let blobToReduce: Blob;
          setUploadState(prevState => {
            if (prevState.previewSrc) {
              URL.revokeObjectURL(prevState.previewSrc)
            }
            return {
              progress: 0,
              processing: true,
              previewSrc: '',
            }
          });
          (async function() {
            if (isHeic) {
              try {
                const heic2anyResult = await heic2any({blob: file, toType: "image/jpeg"})
                if (Array.isArray(heic2anyResult)) {
                  createSnackbar("Multiple images in single file not supported")
                  setUploadState({
                    progress: 0,
                    processing: false,
                    previewSrc: '',
                  })
                  return
                }
                blobToReduce = heic2anyResult
              } catch (e) {
                console.error(e)
                createSnackbar("Could not read HEIC format")
                setUploadState({
                  progress: 0,
                  processing: false,
                  previewSrc: '',
                })
                return
              }
            } else {
              blobToReduce = file
            }
            let originalWidth = 0
            let originalHeight = 0
            const opts = {max: 350, originalWidth: (value: number) => {originalWidth = value}, originalHeight: (value: number) => {originalHeight = value}}
            // @ts-ignore
            let reducedFile: any
            try {
              reducedFile = await reduce.toBlob(blobToReduce, opts)
            } catch (e) {
              console.error(e)
              createSnackbar("File format not supported")
              setUploadState({
                progress: 0,
                processing: false,
                previewSrc: '',
              })
              return
            }
            setUploadState({
                progress: 0,
                processing: false,
                previewSrc: URL.createObjectURL(reducedFile),
            })

            const randomFileName = `${uuidv4()}.${extension}`

            const doc = await firebase.firestore()
              .collection("users")
              .doc(user.uid)
              .collection("pictures")
              .add({
                filename: randomFileName,
                uploaded: false,
                timestamp: Date.now(),
                width: originalWidth,
                height: originalHeight
              })

            await new Promise<void>((resolve, reject) => {
              firebase.storage().ref(`/user-data/${user.uid}/pictures/${doc.id}/thumb/${randomFileName}`)
                .put(reducedFile).on(STATE_CHANGED, {
                  complete: () => resolve(),
                  error: cause => reject(cause)
                })
            })
            setUploadTask({
              ref: `/user-data/${user.uid}/pictures/${doc.id}/full/${randomFileName}`,
              blob: blobToReduce,
              userId: user.uid,
              imageId: doc.id
            })
          })()
        } else {
          console.error('file does not have extension')
        }
      }
    }
    const obj = inputFile.current
    if (obj) {
      obj.value = ''
    }
  }

  return (
    <React.Fragment>
      <input type='file' id='file' ref={inputFile} onChange={onChangeHandler} style={{display: 'none'}}/>
      <div ref={ref} className='placeholder placeholder-filled'>
        <AddCircleIcon style={{
          width: bounds.width / 4,
          height: bounds.width / 4
        }}/>
      </div>
      {uploadState.previewSrc || uploadState.processing ? <React.Fragment>
          {uploadState.previewSrc && <img src={uploadState.previewSrc} alt={'miniature-upload'}/>}
          <div className='placeholder'>
            <div className='upload-background' style={{
              width: bounds.width * 0.35,
              height: bounds.width * 0.35,
              filter: `blur(${bounds.width * 0.02}px)`
            }}/>
          </div>
          <div className='placeholder'>
            <CircularProgress variant={uploadState.processing ? "indeterminate" : "determinate"} value={uploadState.progress} style={{
              width: bounds.width / 4,
              height: bounds.width / 4
            }}/>
          </div>
        </React.Fragment> :
        <div className='overlay' onClick={() => {
          inputFile.current?.click()
        }} style={{
          visibility: focused ? 'visible' : 'hidden',
        }}/>}
    </React.Fragment>
  )
}

export default MiniatureAddPhoto

import React, {ReactNode, useContext} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import {createMuiTheme, CssBaseline, MuiThemeProvider} from "@material-ui/core";
import FirebaseContextProvider from "./firebase/FirebaseContextProvider";
import ShareDialogProvider from "./components/ShareDialogProvider";
import SnackbarProvider from "./components/SnackbarProvider";
import InstallPromptProvider from "./hooks/InstallPromptProvider";
import StageProvider from "./components/StageProvider";
import DeleteDialogProvider from "./components/DeleteDialogProvider";
import ThemeProvider, {ThemeContext} from "./hooks/ThemeProvider";
import PresenterProvider from "./hooks/PresenterProvider";

/*
const theme = createMuiTheme({
  palette: {
    background: {
      default: "#f5f5f5",
    },
    primary: {
      main: '#000'
    }
  },
});
*/

function CustomMuiThemeProvider({children}: {children: ReactNode}) {
  const {darkTheme} = useContext(ThemeContext)
  return <MuiThemeProvider theme={createMuiTheme({
    palette: {
      background: {
        default: darkTheme ? "#000" : "#f5f5f5"
      },
      primary: {
        main: "#000"
      }
    }
  })}>
    {children}
  </MuiThemeProvider>
}

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider>
      <InstallPromptProvider>
        <StageProvider>
          <FirebaseContextProvider>
            <BrowserRouter>
              <CustomMuiThemeProvider>
                <CssBaseline />
                <SnackbarProvider>
                  <ShareDialogProvider>
                    <DeleteDialogProvider>
                      <PresenterProvider>
                        <App />
                      </PresenterProvider>
                    </DeleteDialogProvider>
                  </ShareDialogProvider>
                </SnackbarProvider>
              </CustomMuiThemeProvider>
            </BrowserRouter>
          </FirebaseContextProvider>
        </StageProvider>
      </InstallPromptProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

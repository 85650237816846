import React, {createContext, ReactNode} from 'react';

interface State {
  darkTheme: boolean
  setDarkTheme: (value: boolean) => void
}

export const ThemeContext = createContext<State>({
  darkTheme: false,
  setDarkTheme: () => {}
})

function ThemeProvider({children}: {children: ReactNode}) {

  //const detectedTheme = useThemeDetector()
  //const [darkTheme, setDarkTheme] = useState<string | null>(localStorage.getItem("darkTheme"))

  return (
    <ThemeContext.Provider value={{
      // darkTheme: darkTheme == null ? detectedTheme : darkTheme === "true",
      darkTheme: false,
      setDarkTheme: (value: boolean) => {
/*
        if (value) {
          localStorage.setItem("darkTheme", "true")
          setDarkTheme("true")
        } else {
          localStorage.setItem("darkTheme", "false")
          setDarkTheme("false")
        }
*/
      }
    }}>
      {children}
    </ThemeContext.Provider>
  )
}

export default ThemeProvider

import React, {Dispatch, SetStateAction, useEffect, useState} from 'react'
import {animated, useSpring} from "react-spring";
import {useGesture} from "react-use-gesture";
import "./Picture.css";
import {CircularProgress} from "@material-ui/core";

interface Size {
  height: number,
  width: number
}

interface PictureProps {
  src?: string,
  progress?: number,
  zoom: boolean,
  setZoom: Dispatch<SetStateAction<boolean>>,
  zoomPossible: boolean,
  size: Size,
  boundsWider: boolean,
  bounds: {
    width: number,
    height: number
  }
}

const Picture = ({src, progress, bounds, boundsWider, zoom, setZoom, zoomPossible, size}: PictureProps) => {
  const [shift, setShift] = useState<{ value: number, initial: number }>({value: 0, initial: 0})
  const [dragging, setDragging] = useState<boolean>(false)
  const [{springZoom}, springSet] = useSpring<{ springZoom: number }>(() => ({springZoom: 0}))
  // const fadeIn = useSpring({opacity: src ? 1 : 0})

  useEffect(() => {
    springSet({springZoom: zoom ? 1 : 0})
  }, [zoom, springSet])

  const calcMaxDim1ImgDim1 = (zoom: number, boundsDim1: number, boundsDim2: number, sizeDim1: number, sizeDim2: number): { maxDim1: number, imgDim1: number } => {
    const maxPictureDim1 = Math.min(sizeDim1, boundsDim2 * (sizeDim1 / sizeDim2))
    const maxDim1 = boundsDim1 + zoom * (maxPictureDim1 - boundsDim1)
    const imgDim1 = Math.min(boundsDim2 * (sizeDim1 / sizeDim2), maxDim1)
    return {
      maxDim1,
      imgDim1
    }
  }

  const zoomShift = (zoom: number, shift: number, boundsDim1: number, boundsDim2: number, sizeDim1: number, sizeDim2: number): { maxDim1: number, endDim1: number } => {
    const {maxDim1, imgDim1} = calcMaxDim1ImgDim1(zoom, boundsDim1, boundsDim2, sizeDim1, sizeDim2)
    const endDim1 = sizeDim1 > boundsDim1 ? shift * (imgDim1 - boundsDim1) : (sizeDim1 - boundsDim1) / 2
    return {
      maxDim1,
      endDim1
    }
  }

  const shiftFromMovement = (zoom: number, movement: number, boundsDim1: number, boundsDim2: number, sizeDim1: number, sizeDim2: number): number => {
    const {imgDim1} = calcMaxDim1ImgDim1(zoom, boundsDim1, boundsDim2, sizeDim1, sizeDim2)
    return movement / (imgDim1 - boundsDim1)
  }

  useEffect(() => {
    if (!zoomPossible) {
      setZoom(false)
    }
  }, [setZoom, zoomPossible])

  const bind = useGesture({
    onDrag: state => {
      if (zoom) {
        setDragging(state.down)
        if (state.first) {
          setShift(prevState => ({
            ...prevState,
            initial: prevState.value
          }))
        } else {
          setShift(prevState => {
            return boundsWider ? {
              ...prevState,
              value: Math.min(Math.max(prevState.initial - shiftFromMovement(1, state.movement[1], bounds.height, bounds.width, size.height, size.width), 0), 1)
            } : {
              ...prevState,
              value: Math.min(Math.max(prevState.initial - shiftFromMovement(1, state.movement[0], bounds.width, bounds.height, size.width, size.height), 0), 1)
            }
          })
        }
      }
    }
  })

  const zoomShiftHeightWidth = (zoom: number, shift: number) => zoomShift(zoom, shift, bounds.height, bounds.width, size.height, size.width)
  const zoomShiftWidthHeight = (zoom: number, shift: number) => zoomShift(zoom, shift, bounds.width, bounds.height, size.width, size.height)

  const imgStyle = boundsWider ? {
    left: '50%',
    transform: 'translateX(-50%)',
    maxWidth: '100%',
    maxHeight: springZoom.interpolate(zoom => zoomShiftHeightWidth(zoom, shift.value).maxDim1),
    bottom: springZoom.interpolate(zoom => zoomShiftHeightWidth(zoom, shift.value).endDim1)
  } : {
    top: '50%',
    transform: 'translateY(-50%)',
    maxHeight: '100%',
    maxWidth: springZoom.interpolate(zoom => zoomShiftWidthHeight(zoom, shift.value).maxDim1),
    right: springZoom.interpolate(zoom => zoomShiftWidthHeight(zoom, shift.value).endDim1)
  }

  return (
    <React.Fragment>
      {src ? <React.Fragment>
        <animated.img alt='picture' src={src} style={{position: 'relative', ...imgStyle, /*...fadeIn*/}}/>
        <div style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          touchAction: 'none',
          cursor: dragging ? 'grabbing' : zoom ? 'grab' : 'auto'
        }} {...bind()} onDoubleClick={event => {
          if (!zoom && zoomPossible) {
            boundsWider ? setShift({
              value: event.clientY / bounds.height,
              initial: 0
            }) : setShift({value: event.clientX / bounds.width, initial: 0})
          }
          setZoom(prevState => !prevState && zoomPossible)
        }} onMouseDown={event => event.preventDefault()}/>
      </React.Fragment> : <div style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}><CircularProgress style={{color: 'white'}} variant='determinate' value={progress}/></div>}
    </React.Fragment>
  )
}

export default Picture

import React, {useContext, useEffect, useRef, useState} from 'react'
import useMeasure from "react-use-measure";
import {useHistory, useParams} from "react-router-dom";
import Appbar from "./Appbar";
import {FirebaseContext} from "../firebase/FirebaseContextProvider";
import "./Profile.css"
import Miniature from "./Miniature";
import MiniatureAddPhoto from "./MiniatureAddPhoto";
import useImageList from "../hooks/useImageList";
import useUserId from "../hooks/useUserId";
import {Stage, StageContext} from "./StageProvider";
import {CircularProgress, IconButton} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import {DeleteDialogContext} from "./DeleteDialogProvider";
import {ThemeContext} from "../hooks/ThemeProvider";
import { ResizeObserver } from '@juggle/resize-observer';
import {Helmet} from "react-helmet";

const Profile = () => {

  const [navRef, navBounds] = useMeasure({polyfill: ResizeObserver})
  const spinnerRef = useRef<HTMLDivElement | null>(null)
  const mobileFormat = navBounds.width < 700
  const [focusedImageNumber, setFocusedImageNumber] = useState<number>(-2)
  const {userHandle} = useParams<{ userHandle: string }>()
  const {firebase, user, handle} = useContext(FirebaseContext)
  const {setStage} = useContext(StageContext)
  const {openDeleteDialog} = useContext(DeleteDialogContext)
  const userId = useUserId({
    value: userHandle,
    ready: true
  }, firebase)
  const [numberRequested, setNumberRequested] = useState<number>(9)
  const {imageList, readInFull, reset} = useImageList(userId, firebase, numberRequested)
  const [userEnabled, setUserEnabled] = useState<{
    ready: boolean,
    enabled: boolean
  }>({
    ready: false,
    enabled: false
  })
  const history = useHistory();
  const {darkTheme} = useContext(ThemeContext)

  useEffect(() => {
    const updateNumberRequested = () => {
      const element = document.scrollingElement
      if (element) {
        const client = element.clientHeight
        if (spinnerRef) {
          const offsetTop = spinnerRef.current?.getBoundingClientRect().top
          if (offsetTop) {
            if (offsetTop - client < 0 && numberRequested <= imageList.length) {
              setNumberRequested(prevState => prevState + 9)
            }
          }
        }
      }
    }

    updateNumberRequested()
    document.addEventListener("scroll", updateNumberRequested)
    window.addEventListener("resize", updateNumberRequested)

    return () => {
      document.removeEventListener("scroll", updateNumberRequested)
      window.removeEventListener("resize", updateNumberRequested)
    }
  }, [numberRequested, imageList.length])

  useEffect(() => {
    if (firebase && user) {
      firebase.firestore().collection("users").doc(user.uid).get()
        .then(doc => {
          if (doc.exists) {
            const data = doc.data()
            if (data) {
              setUserEnabled({
                ready: true,
                enabled: data.enabled
              })
            }
          }
        })
    }
  }, [firebase, user])

  const deleteImage = (imageId: string, imageFilename: string) => {
    openDeleteDialog(imageId, imageFilename, () => {
      reset()
    })
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>{`${userHandle} | Andagram`}</title>
        {userHandle !== "settings" && <link rel="alternate" type="application/rss+xml" title={`${userHandle} RSS feed`} href={`https://andagram.com/${userHandle}/feed`} />}
      </Helmet>
      <Appbar mobileFormat={mobileFormat} userHandle={userHandle} withIcons={true}/>
      {mobileFormat && <div className='profile-name' style={{
        color: darkTheme ? "#fff" : "#000",
        borderBottomColor: darkTheme ? "#444" : "#DDD"
      }}>{userHandle}</div>}
      {userEnabled.ready && !userEnabled.enabled && <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'black',
        fontSize: 18,
        fontFamily: 'Raleway, sans-serif',
        margin: '0 auto',
        textAlign: 'center',
        borderBottom: '1px solid #DDDDDD',
        paddingTop: '1rem',
        paddingBottom: '1rem'
      }}>
        Andagram is currently in beta.<br/><br/>We will let you know when we go live.<br/><br/>If you want to use it
        right away, you can request invitation: hi@andagram.com
      </div>
      }
      {userHandle === 'settings' && user && <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'black',
        fontSize: 15,
        fontFamily: 'Raleway, sans-serif',
        margin: '0 auto',
        textAlign: 'center',
        borderBottom: '1px solid #DDDDDD',
        paddingTop: '1rem',
        paddingBottom: '1rem'
      }}>
        <div onClick={() => {
          firebase?.auth().signOut().then(() => {
            setStage(Stage.signUp)
            history.push("/")
          })
        }} className='settings-logout-button'>Logout
        </div>
      </div>
      }
      <div ref={navRef} className='gallery' style={{
        padding: mobileFormat ? '0.2rem' : '1.5rem',
        marginTop: mobileFormat ? '-0.2rem' : '-1.5rem'
      }}>
        {userHandle !== 'settings' && <div className='grid' style={{
          gridGap: mobileFormat ? '0.2rem' : '1.5rem',
          paddingTop: mobileFormat ? '0.2rem' : '1.5rem'
        }}>
          {user && handle.value === userHandle && userEnabled.enabled &&
          <div className='container' onMouseEnter={() => setFocusedImageNumber(-1)}
               onMouseLeave={() => setFocusedImageNumber(-2)}>
            <MiniatureAddPhoto reset={reset} focused={focusedImageNumber === -1}/>
          </div>}
          {imageList.map((image, i) =>
              <div key={`container-${i}`} className='container' onMouseEnter={() => setFocusedImageNumber(i)}
                   onMouseLeave={() => setFocusedImageNumber(-2)}>
                <Miniature id={i} url={`/user-data/${image.userId}/pictures/${image.imageId}/thumb/${image.filename}`}
                           focused={focusedImageNumber === i} firebase={firebase} />
                <div className='overlay' onClick={() => history.push(`/${userHandle}/${image.imageId}`)} style={{
                  opacity: focusedImageNumber === i ? 0.35 : 0,
                }}/>
                {user && handle.value === userHandle && <div style={{
                  width: 50,
                  height: 50
                }}>
                  <IconButton onClick={() => deleteImage(image.imageId, image.filename)}>
                    <DeleteIcon />
                  </IconButton>
                </div>}
              </div>
          )}
        </div>}
      </div>
      {!readInFull && userHandle !== "settings" && <div ref={spinnerRef} style={{
        height: 100,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <CircularProgress />
      </div>}
      {/*
      <img src={src} alt={'asd'} style={{
        width: 500,
        height: 500,
        backgroundColor: 'red'
      }} />
*/}
    </React.Fragment>
  )
}

export default Profile

import {useEffect, useState} from "react";
import firebase from "firebase/app";
import {Handle} from "../firebase/FirebaseContextProvider";

function useUserId(handle: Handle, firebase: firebase.app.App | null) {
  const [userId, setUserId] = useState<string | null>(null)

  useEffect(() => {
    let cancelled = false
    if (handle.value) {
      firebase?.firestore().collection("handles").doc(handle.value).get()
        .then(doc => {
          if (doc.exists) {
            const uid: string = doc.get("uid")
            if (!cancelled) {
              setUserId(uid)
            }
          }
        })
      return () => {
        cancelled = true
      }
    }
  }, [firebase, handle])

  return userId
}

export default useUserId

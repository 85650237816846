import React, {FormEventHandler, useContext, useEffect, useState} from 'react';
import './Home.css'
import {TextField} from "@material-ui/core";
import {FirebaseContext} from "../firebase/FirebaseContextProvider";
import {Link} from 'react-router-dom'
import firebase from "firebase";
import {useHistory} from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';
import useImageWithProgress from "../hooks/useImageWithProgress";
import {useSpring, animated} from "react-spring";
/*
import {loadStripe} from '@stripe/stripe-js';
*/
import {Stage, StageContext} from "./StageProvider";
import {SnackbarContext} from "./SnackbarProvider";
import {Helmet} from "react-helmet";

const actionCodeSettings: firebase.auth.ActionCodeSettings = {
  url: process.env.NODE_ENV === 'production' ? 'https://andagram.com' : 'https://localhost:3000/',
  handleCodeInApp: true
}

const Home = () => {

  const [email, setEmail] = useState<string>('');
  const [username, setUsername] = useState<string>('');
  const [usernameError, setUsernameError] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);
  const {firebase, user, authInitialized, handle} = useContext(FirebaseContext)
  const history = useHistory()
  const {stage, setStage} = useContext(StageContext)
  const {createSnackbar} = useContext(SnackbarContext)

/*
  useEffect(() => {
    if (user && firebase) {
      firebase?.firestore().collection('products')
        .where('active', '==', true)
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(async function (doc) {
            //console.log(doc.id, ' => ', doc.data());
            const priceSnap = await doc.ref.collection('prices').get();
            priceSnap.docs.forEach((doc) => {
              //console.log(doc.id, ' => ', doc.data());
            });
          });
        });
    }
  }, [firebase, user])
*/


  useEffect(() => {
    if (authInitialized) {
      if (!user && firebase && !firebase.auth().isSignInWithEmailLink(window.location.href)) {
        setStage(Stage.signUp)
      } else if (user) {
        setStage(Stage.loggedIn)
        history.push('/')
      }
    }
  }, [authInitialized, user, firebase, history, setStage])

  const handleLoginError = (error: any) => {
/*
    firebase?.firestore().collection("crashlytics").add({
      error: error.toString(),
      timestamp: Date.now(),
      context: "signing in with stored email"
    })
*/
    console.log(error)
    if (error.code === "auth/invalid-email") {
      setEmailError(true)
      setStage(Stage.signUp)
      createSnackbar("Invalid email")
    } else if (error.code === "auth/invalid-action-code") {
      createSnackbar("This link has expired, please request a new one")
      setStage(Stage.signUp)
      setEmail('')
    } else {
      setStage(Stage.signUp)
      setEmail('')
      createSnackbar('Failed to log in: ' + error.code)
    }
  }

  useEffect(() => {
    setEmailError(false)
  }, [email])

  useEffect(() => {
    const handleLoginErrorEffect = (error: any) => {
/*
      firebase?.firestore().collection("crashlytics").add({
        error: error.toString(),
        timestamp: Date.now(),
        context: "signing in with stored email"
      })
*/
      console.log(error)
      if (error.code === "auth/invalid-email") {
        setStage(Stage.emailConfirmation)
        setEmail('')
      } else if (error.code === "auth/invalid-action-code") {
        createSnackbar("This link has expired, please request a new one")
        setStage(Stage.signUp)
        setEmail('')
      } else {
        setStage(Stage.signUp)
        setEmail('')
        createSnackbar('Failed to log in: ' + error.code)
      }
    }
    if (firebase?.auth().isSignInWithEmailLink(window.location.href)) {
      const storedEmail = window.localStorage.getItem('emailForSignIn')
      if (!storedEmail) {
        setStage(Stage.emailConfirmation)
      } else {
        window.localStorage.removeItem('emailForSignIn')
        firebase?.auth().signInWithEmailLink(storedEmail, window.location.href)
          .catch(handleLoginErrorEffect)
      }
    }
  }, [firebase, setStage, createSnackbar])

  const onSubmit: FormEventHandler = event => {
    event.preventDefault();
    setStage(Stage.spinner)
    firebase?.auth().sendSignInLinkToEmail(email, actionCodeSettings)
      .then(() => {
        setStage(Stage.emailSent)
        window.localStorage.setItem('emailForSignIn', email)
      })
      .catch(handleLoginError)
  }

  const onConfirm: FormEventHandler = event => {
    event.preventDefault()
    setStage(Stage.spinner)
    firebase?.auth().signInWithEmailLink(email, window.location.href).catch(handleLoginError)
  }

  const usernameSubmit: FormEventHandler = event => {
    event.preventDefault()
    setStage(Stage.spinner)
    if (firebase && user) {
      if (username === 'settings' || username === 'about') {
        createSnackbar("Username not available")
        setUsernameError(true)
      } else if (username) {
        (async function() {
          const handleDoc = await firebase.firestore().collection("handles").doc(username).get()
          if (handleDoc.exists) {
            setStage(Stage.loggedIn)
            createSnackbar("Username not available")
            setUsernameError(true)
          } else {
            try {
              await firebase.firestore().collection("handles").doc(username).set({
                uid: user.uid
              })
              await firebase.firestore().collection("users").doc(user.uid).set({
                handle: username
              })
            } catch (e) {
              console.error(e)
            } finally {
              setStage(Stage.loggedIn)
            }
          }
        })();
      } else {
        createSnackbar("Please provide username")
        setUsernameError(true)
      }
    } else {
      createSnackbar("Something went wrong, try again")
    }
  }

  const {src} = useImageWithProgress('/public/wallpaper.jpg', firebase)

  const props = useSpring({
    opacity: src ? 1 : 0
  })

/*
  const handleSubscribe = async () => {
    if (user && firebase) {
        const docRef = await firebase.firestore()
          .collection('customers')
          .doc(user.uid)
          .collection('checkout_sessions')
          .add({
            price: 'price_1IIxTpFW5UBvNLYbuYZIKRQu',
            success_url: window.location.origin,
            cancel_url: window.location.origin,
          });

        docRef.onSnapshot(async (snap) => {
          const data = snap.data();
          if (data) {
            const { error, sessionId } = data;
            if (error) {
              // Show an error to your customer and
              // inspect your Cloud Function logs in the Firebase console.
              alert(`An error occured: ${error.message}`);
            }
            if (sessionId) {
              // We have a session, let's redirect to Checkout
              // Init Stripe
                const stripe = await loadStripe("pk_live_51IIxAwFW5UBvNLYbnYk2qhFtUZRH1ZaH9d1wAvP14RQ49rvhhHHDmwpvSOR4VdTWyTFwbih2knwHEU7RWHRFq0cL00bt2IIC3F");
                if (stripe) {
                  await stripe.redirectToCheckout({ sessionId });
                }
            }
          }
        });
    }
  }
*/

  return (
    <React.Fragment>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        bottom: 0,
        height: 100,
        width: '100%',
        zIndex: 60
      }}>
        <Link to={'/about'} style={{ textDecoration: 'none' }} >
          <button className='login-form-button' style={{
            marginTop: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 180,
            textDecoration: "none"
          }}>
            What is Andagram?
          </button>
        </Link>
      </div>
      <div className='home-background'>
        <Helmet>
          <title>{'Andagram'}</title>
        </Helmet>
{/*
        {src && stage === Stage.signUp && <animated.img style={props} draggable={false} src={src} alt='wallpaper' />}
*/}
        <animated.img style={props} draggable={false} src={src} alt='wallpaper' />

        </div>
        <div className='login-widget' style={{
          zIndex: 100
        }}>
          <div className='login-widget-content'>
            <div className='logo'>
              Andagram
            </div>
            {(stage === Stage.spinner || (stage === Stage.loggedIn && (!user || !handle.ready))) &&
            <div style={{
              paddingTop: '2rem',
              paddingBottom: '2rem'
            }}>
              <CircularProgress />
            </div>}
            {stage === Stage.emailSent && <div className='login-form-text'>
              Login link has been sent to your email. It's single-use only. Remember to always use the most recent one.
            </div>}
            {stage === Stage.emailConfirmation &&
            <React.Fragment>
              <div className='login-form' style={{
                paddingBottom: '0.8rem'
              }}>
                <form onSubmit={onConfirm} style={{
                  width: '100%'
                }} noValidate autoComplete='off'>
                  <TextField type='email' error={emailError} onChange={(event) => setEmail(event.target.value)} value={email} fullWidth={true} variant='outlined' label='Email'/>
                  <button className='login-form-button' type='submit'>Confirm email</button>
                </form>
              </div>
            </React.Fragment>

            }
            {/*
          {stage === Stage.loggedIn && user &&
            <React.Fragment>
              <div className='login-form-text'>{user.email}</div>
              <Link to={`/${handle}`}>
                <button className='login-form-button'>Go to your profile</button>
              </Link>
              <div>
                <a href='/' onClick={logOut} className='example-link login-form-text'>
                  Log out
                </a>
              </div>
              <div>
                <a href='#' onClick={handleSubscribe} className='example-link login-form-text'>
                  Subscribe
                </a>
              </div>
            </React.Fragment>}
*/}
            {stage === Stage.signUp &&
            <React.Fragment>
              <div className='login-form'>
                <form onSubmit={onSubmit} style={{
                  width: '100%'
                }} noValidate autoComplete='off'>
                  <TextField type='email' error={emailError} onChange={(event) => setEmail(event.target.value)} value={email} fullWidth={true} variant='outlined' label='Email'/>
                  <button className='login-form-button' type='submit'>Continue with email</button>
                </form>
              </div>
              <div>
                <Link to='/kamil' className='example-link login-form-text'>
                  Go to demo
                </Link>
              </div>
            </React.Fragment>
            }

            {handle.ready && stage === Stage.loggedIn && <div className='login-form' style={{
              paddingBottom: '0.8rem'
            }}>
              <form onSubmit={usernameSubmit} style={{
                width: '100%'
              }} noValidate autoComplete='off' >
                <TextField fullWidth={true} onChange={(event) => {
                  setUsername(event.target.value)
                  setUsernameError(false)
                }} value={username} variant='outlined' label='Username' error={usernameError}/>
                <button className='login-form-button' type='submit'>Set username</button>
              </form>
            </div> }
          </div>
        </div>
    </React.Fragment>
  )
}

export default Home

import React, {useContext, useEffect, useState} from 'react'
import Picture from "./Picture";
import {Box, createMuiTheme, IconButton, MuiThemeProvider} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import ShareIcon from "@material-ui/icons/Share";
import useMeasure from "react-use-measure";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import BottomNavButton from "./BottomNavButton";
import "./PhotoWithNav.css"
import CloseIcon from '@material-ui/icons/Close';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import useEventListener from "@use-it/event-listener";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {ShareDialogContext} from "./ShareDialogProvider";
import {FirebaseContext} from "../firebase/FirebaseContextProvider";
import useImageList from "../hooks/useImageList";
import useUserId from "../hooks/useUserId";
import {ResizeObserver} from "@juggle/resize-observer";
import {Helmet} from "react-helmet";
import {PresenterContext} from "../hooks/PresenterProvider";
import SlideshowIcon from '@material-ui/icons/Slideshow';
import {SnackbarContext} from "./SnackbarProvider";
import FileDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import { saveAs } from 'file-saver';
import useImageWithProgress from "../hooks/useImageWithProgress";

const theme = createMuiTheme({
  palette: {
    type: 'dark',
  }
});

const PhotoWithNav = () => {

  const {userHandle, imageId} = useParams<{userHandle: string, imageId: string}>()
  const {role, updateSlide, presenter} = useContext(PresenterContext)
  const {createSnackbar} = useContext(SnackbarContext)

  const fullScreenHandle = useFullScreenHandle()
  const history = useHistory()
  const location = useLocation()
  const {openShareDialog} = useContext(ShareDialogContext)
  const {firebase, user, handle} = useContext(FirebaseContext)
  const userId = useUserId({
    value: userHandle,
    ready: true
  }, firebase)
  const {imageList} = useImageList(userId, firebase, -1)

  const [ref, bounds] = useMeasure({polyfill: ResizeObserver})
  const [zoom, setZoom] = useState<boolean>(false)
  const [imageParams, setImageParams] = useState<{
    currentImageIndex: number,
    imageFound: boolean,
    size: {
      width: number,
      height: number
    },
    boundsWider: boolean,
    zoomPossible: boolean,
    nextImageLink?: string
    previousImageLink?: string
  }>({
    currentImageIndex: -1,
    imageFound: false,
    size: {
      width: 0,
      height: 0
    },
    boundsWider: true,
    zoomPossible: false
  })
  const fileName: string | null = imageParams.imageFound ? `/user-data/${imageList[imageParams.currentImageIndex].userId}/pictures/${imageList[imageParams.currentImageIndex].imageId}/full/${imageList[imageParams.currentImageIndex].filename}` : null
  const {src, progress} = useImageWithProgress(fileName, firebase)

  useEffect(() => {
    const currentImageIndex = imageList.findIndex(image => image.imageId === imageId)
    const imageFound = currentImageIndex >= 0

    const size = imageFound ? {
      width: imageList[currentImageIndex].width,
      height: imageList[currentImageIndex].height
    } : {
      width: 0,
      height: 0
    }

    const boundsWider = imageFound ? bounds.width / bounds.height > size.width / size.height : true
    const zoomPossible = imageFound ? boundsWider ? size.height > bounds.height : size.width > bounds.width : false

    const nextImageLink = imageFound && currentImageIndex < imageList.length - 1 ? `/${userHandle}/${imageList[currentImageIndex + 1].imageId}` : undefined
    const previousImageLink = imageFound && currentImageIndex > 0 ? `/${userHandle}/${imageList[currentImageIndex - 1].imageId}` : undefined

    setImageParams({
      currentImageIndex,
      imageFound,
      boundsWider,
      zoomPossible,
      nextImageLink,
      previousImageLink,
      size
    })

  }, [imageId, imageList, userHandle, bounds])

  useEffect(() => {
    setZoom(false)
  }, [imageId, imageList, userHandle])

  const handleZoom = () => {
    setZoom(prevState => !prevState && imageParams.zoomPossible)
  }

  useEventListener('keyup', (event: any) => {
    if (event.key === "ArrowRight" || event.key === "l") {
      if (imageParams.nextImageLink) {
        if (role === null) {
          history.push(imageParams.nextImageLink)
        } else if (role === "host") {
          updateSlide(imageParams.nextImageLink)
        }
      }
    } else if (event.key === "ArrowLeft" || event.key === "h") {
      if (imageParams.previousImageLink) {
        if (role === null) {
          history.push(imageParams.previousImageLink)
        } else if (role === "host") {
          updateSlide(imageParams.previousImageLink)
        }
      }
    }
  })

  return (
    <MuiThemeProvider theme={theme}>
      <Helmet>
        <title>{`${userHandle} | Andagram`}</title>
      </Helmet>
    <Box display='flex' flexDirection='column' style={{
      width: '100%',
      position: 'fixed',
      bottom: 0,
      top: 0
    }}>
      <Box display='flex' justifyContent='space-between' style={{
        backgroundColor: 'black'
      }}>
        <Box display='flex' alignItems='center' style={{
          marginLeft: 15,
          color: 'white',
          fontSize: 15,
          fontFamily: "'Raleway', sans-serif"
        }}>
          {role === "host" ? "Presenting now" : role === "guest" && presenter !== null ? `Presented by ${presenter}` : ""}
        </Box>
        <Box display='flex' justifyContent='flex-end'>
          <IconButton onClick={fullScreenHandle.enter} aria-label='fullscreen'>
            <FullscreenIcon />
          </IconButton>
          <BottomNavButton icon={<CloseIcon />} disabled={false} to={`/${userHandle}`} ariaLabel='user' />
        </Box>
      </Box>
      <FullScreen handle={fullScreenHandle}>
        <div ref={ref} style={{
          position: 'relative',
          width: '100%',
          height: '100%'
        }}>
          {imageParams.imageFound && <Picture src={src} progress={progress} key={imageParams.currentImageIndex.toString()} size={imageParams.size} bounds={bounds} boundsWider={imageParams.boundsWider} zoom={zoom} setZoom={setZoom} zoomPossible={imageParams.zoomPossible}/>}
        </div>
      </FullScreen>
      <Box display='flex' justifyContent='space-between' style={{
        backgroundColor: 'black'
      }}>
        <BottomNavButton onClick={role === "host" ? () => {imageParams.previousImageLink && updateSlide(imageParams.previousImageLink)} : undefined} icon={<ArrowBackIcon />} disabled={!imageParams.imageFound || !imageParams.previousImageLink || (role === "guest")} to={role === null ? imageParams.previousImageLink || '#' : undefined} ariaLabel={'back'} />
        <Box display='flex'>
          <BottomNavButton icon={zoom ? <ZoomOutIcon /> : <ZoomInIcon />} disabled={!imageParams.imageFound || !imageParams.zoomPossible} onClick={handleZoom} ariaLabel={'zoom'} />
          <BottomNavButton icon={<ShareIcon />} disabled={!imageParams.imageFound} ariaLabel='share' onClick={() => openShareDialog(`https://andagram.com${location.pathname}`)} />
          <BottomNavButton icon={<FileDownloadOutlinedIcon />} disabled={!imageParams.imageFound || !src} ariaLabel='download' onClick={async () => {
            if (src && fileName) {
              saveAs(src, fileName.split("/").reverse()[0])
            }
          }} />
          <BottomNavButton icon={<SlideshowIcon />} disabled={!imageParams.imageFound || (user !== null && !handle.ready)} ariaLabel={'slideshow'} onClick={() => {
            if (role === "guest") {
              createSnackbar("Quit this presentation to start a new one")
              return
            }
            if (!user) {
              createSnackbar("Log in to host presentation")
              return
            }
            updateSlide(`/${userHandle}/${imageId}`).then(() => {
              history.push({
                pathname: `/${userHandle}/${imageId}`,
                search: `?presenter=${handle.value}`
              })
              openShareDialog(`https://andagram.com/${userHandle}/${imageId}?presenter=${handle.value}`)
            })
          }}/>
        </Box>
        <BottomNavButton onClick={role === "host" ? () => {imageParams.nextImageLink && updateSlide(imageParams.nextImageLink)} : undefined} icon={<ArrowForwardIcon />} disabled={!imageParams.imageFound || !imageParams.nextImageLink || (role === "guest")} ariaLabel='forward' to={role === null ? imageParams.nextImageLink || '#' : undefined} />
      </Box>
    </Box>
    </MuiThemeProvider>
  )
}

export default PhotoWithNav


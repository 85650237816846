import React, {createContext, ReactNode, useEffect, useState} from 'react';

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyD-F-Y5WdWrguRdVux53A4J0UdtmK1H3FM",
  authDomain: "andagram-518a0.firebaseapp.com",
  databaseURL: "https://andagram-518a0-default-rtdb.firebaseio.com",
  projectId: "andagram-518a0",
  storageBucket: "andagram-518a0.appspot.com",
  messagingSenderId: "494714842380",
  appId: "1:494714842380:web:b0144c6370df55f840ad29"
};

// const app = firebase.initializeApp(firebaseConfig)

// const storage = app.storage()
// const ref = storage.ref()
// const child = ref.child('image/test.jpg')
// child.getDownloadURL()
// https://stackoverflow.com/a/42196770/2220568

export interface Handle {
  value: string | null,
  ready: boolean
}

interface State {
  firebase: firebase.app.App | null;
  user: firebase.User | null;
  authInitialized: boolean;
  handle: Handle;
}

export const FirebaseContext = createContext<State>({
  firebase: null,
  user: null,
  authInitialized: false,
  handle: {
    value: null,
    ready: false
  }
})

const FirebaseContextProvider = ({children}: {children: ReactNode}) => {

  const [app, setApp] = useState<firebase.app.App | null>(null)
  const [user, setUser] = useState<firebase.User | null>(null)
  const [authInitialized, setAuthInitialized] = useState<boolean>(false)
  const [handle, setHandle] = useState<Handle>({
    value: null,
    ready: false
  })


  useEffect(() => {
/*
    let cancelled = false
*/
    const instance = firebase.initializeApp(firebaseConfig)
    setApp(instance)
/*
    instance.firestore().enablePersistence().then(() => {
      if (!cancelled) {
        setApp(instance)
      }
    })
*/
/*
    return () => {
      cancelled = true
    }
*/
  }, [])

  useEffect(() => {
    let cancelled = false;
    let unsubscribe: () => void = () => {}
    if (app && user) {
      unsubscribe()
      unsubscribe = app.firestore()
        .collection("users")
        .doc(user.uid)
        .onSnapshot(next => {
          if (next.exists) {
            const receivedHandle = next.get("handle")
            if (!cancelled) {
              setHandle({
                value: receivedHandle,
                ready: true
              })
            }
          } else {
            if (!cancelled) {
              setHandle({
                value: null,
                ready: true
              })
            }
          }
        })
/*
      app.firestore().collection("users").doc(user.uid).get()
        .then(doc => {
          if (!cancelled) {
            if (doc.exists) {
              const receivedHandle = doc.get("handle")
              if (receivedHandle) {
                setHandle(doc.get("handle"))
              }
            }
          }
        })
*/
    }
    return () => {
      cancelled = true
      unsubscribe()
    }
  }, [app, user])

  useEffect(() => {
    if (app) {
      const unsubscribe = app.auth().onAuthStateChanged(user => {
        setAuthInitialized(true)
        setUser(user)
      })
      return () => unsubscribe()
    }
  }, [app])

  return (
    <FirebaseContext.Provider value={{firebase: app, user, authInitialized, handle}}>
      {children}
    </FirebaseContext.Provider>
  )
}

export default FirebaseContextProvider

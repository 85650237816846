import React, {createContext, ReactNode, useContext, useState} from 'react';
import {
  createMuiTheme,
  Dialog,
  IconButton,
  MuiThemeProvider
} from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import {SnackbarContext} from "./SnackbarProvider";
import {FirebaseContext} from "../firebase/FirebaseContextProvider";


interface DeleteDialogState {
  openDeleteDialog: (imageId: string, imageFilename: string, callback: () => void) => void
}

export const DeleteDialogContext = createContext<DeleteDialogState>({
  openDeleteDialog: () => {}
})

const theme = createMuiTheme({
  palette: {
    type: 'dark',
  }
});

function DeleteDialogProvider({children}: {children: ReactNode}) {
  const [open, setOpen] = useState<boolean>(false)
  const [image, setImage] = useState<{
    id: string,
    filename: string
  } | null>(null)
  const [callback, setCallback] = useState<{
    fun: () => void
  }>({
    fun: () => {}
  })
  const {createSnackbar} = useContext(SnackbarContext)
  const {firebase, user} = useContext(FirebaseContext)

  const handleDelete = () => {
    if (firebase && user && image) {
      (async function() {
        await firebase.firestore().collection("users").doc(user.uid)
          .collection("pictures").doc(image.id).delete()
        await firebase.storage().ref(`user-data/${user.uid}/pictures/${image.id}/full/${image.filename}`).delete()
        await firebase.storage().ref(`user-data/${user.uid}/pictures/${image.id}/thumb/${image.filename}`).delete()
        createSnackbar("Image deleted!")
        setOpen(false)
        callback.fun()
      })()
    }
  }

  return (
    <DeleteDialogContext.Provider value={{
      openDeleteDialog: (imageId, imageFilename, callbackIn) => {
        setImage({
          id: imageId,
          filename: imageFilename
        })
        setOpen(true)
        setCallback({
          fun: callbackIn
        })
      }
    }}>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <div style={{
          padding: '1.5rem'
        }}>
          <MuiThemeProvider theme={theme}>
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                cursor: 'pointer'
              }}>
                <IconButton onClick={handleDelete} style={{
                  backgroundColor: 'black',
                  width: 50,
                  height: 50
                }}>
                  <DeleteIcon/>
                </IconButton>
                <div style={{
                  marginTop: 10
                }}>
                  Click the icon to confirm
                </div>
              </div>
          </MuiThemeProvider>
        </div>
      </Dialog>
      {children}
    </DeleteDialogContext.Provider>
  )
}

export default DeleteDialogProvider

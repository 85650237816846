import firebase from "firebase/app";
import {useEffect, useState} from "react";
import {ImageInfo} from "../api/types";

interface StorageImageInfo {
  filename: string
  height: number
  width: number,
  uploaded: boolean,
  timestamp: number
}

interface PaginatedImages {
  imageList: ImageInfo[],
  last: firebase.firestore.QueryDocumentSnapshot | null,
  readInFull: boolean
}

function useImageList(userId: string | null, firebase: firebase.app.App | null, numberRequested: number) {
  //const userId = useUserId(handle, firebase)
  const [paginatedImageInfo, setPaginatedImageInfo] = useState<PaginatedImages>({
    imageList: [],
    last: null,
    readInFull: false
  })
/*
  const [imageList, setImageList] = useState<ImageInfo[]>([])
*/

  useEffect(() => {
    let cancelled = false
    if (userId && firebase) {
      if ((numberRequested < 0 || paginatedImageInfo.imageList.length < numberRequested) && !paginatedImageInfo.readInFull) {
        const query = firebase.firestore()
          .collection("users")
          .doc(userId)
          .collection("pictures")
          .orderBy("timestamp", "desc");
        (async function () {
          const numberOfMissingImages = numberRequested - paginatedImageInfo.imageList.length;
          const newSnapshots = numberRequested < 0 ? await query.get() : paginatedImageInfo.last ? await query.startAfter(paginatedImageInfo.last).limit(numberOfMissingImages).get() : await query.limit(numberOfMissingImages).get()
          if (!cancelled) {
            if (newSnapshots.empty) {
              setPaginatedImageInfo(prevState => ({...prevState, readInFull: true}))
            } else {
              const filteredList: ImageInfo[] = []
              newSnapshots.forEach(doc => {
                const storageImageInfo: StorageImageInfo = doc.data() as StorageImageInfo
                if (storageImageInfo.uploaded) {
                  const imageInfo: ImageInfo = {
                    userId,
                    imageId: doc.id,
                    width: storageImageInfo.width,
                    height: storageImageInfo.height,
                    filename: storageImageInfo.filename,
                    timestamp: storageImageInfo.timestamp
                  }
                  filteredList.push(imageInfo)
                }
              })
              setPaginatedImageInfo(prevState => ({
                imageList: [...prevState.imageList, ...filteredList],
                last: newSnapshots.docs[newSnapshots.docs.length-1],
                readInFull: numberRequested < 0
              }))
            }
          }
        })()
      }
    }
    return () => {
      cancelled = true
    }
  }, [paginatedImageInfo, userId, firebase, numberRequested])

/*
  useEffect(() => {
    let cancelled = false
    let unsubscribe: any = null
    if (userId) {
      unsubscribe = firebase?.firestore()
        .collection("users")
        .doc(userId)
        .collection("pictures")
        .onSnapshot(list => {
          const filteredList: ImageInfo[] = []
          list.forEach(doc => {
            const storageImageInfo: StorageImageInfo = doc.data() as StorageImageInfo
            if (storageImageInfo.uploaded) {
              const imageInfo: ImageInfo = {
                userId,
                imageId: doc.id,
                width: storageImageInfo.width,
                height: storageImageInfo.height,
                filename: storageImageInfo.filename,
                timestamp: storageImageInfo.timestamp
              }
              filteredList.push(imageInfo)
            }
          });
          if (!cancelled) {
            setImageList(filteredList.sort((v1, v2) => v2.timestamp - v1.timestamp))
          }
        })
    }
    return () => {
      cancelled = true
      if (unsubscribe) {
        unsubscribe()
      }
    }
  }, [userId, firebase])
*/

  return {
    imageList: paginatedImageInfo.imageList,
    readInFull: paginatedImageInfo.readInFull,
    reset: () => {
      setPaginatedImageInfo({
        imageList: [],
        last: null,
        readInFull: false
      })
    }
  }
}

export default useImageList

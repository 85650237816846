import React, {useEffect, useState} from 'react'
import {CircularProgress} from "@material-ui/core";
import "./Miniature.css"
import useImageWithProgress from "../hooks/useImageWithProgress";
import useMeasure from "react-use-measure";
import firebase from "firebase/app";
import { ResizeObserver } from '@juggle/resize-observer'

interface MiniatureProps {
  id: number,
  url: string,
  focused: boolean,
  firebase: firebase.app.App | null
}

function Miniature({id, url, focused, firebase}: MiniatureProps) {

  const {src} = useImageWithProgress(url, firebase)
  //const props = useSpring({opacity: src ? 1 : 0})
  const [loading, setLoading] = useState<boolean>(false)
  const [ref, bounds] = useMeasure({polyfill: ResizeObserver})

  useEffect(() => {
    let cancelled = false
    setTimeout(() => {
      if (!cancelled) {
        setLoading(true)
      }
    }, 500)

    return () => {
      cancelled = true
    }
  }, [])

  return (
    <React.Fragment>
      {src ? <img src={src} alt={`miniature-${id}`} /> : loading && <div ref={ref} className='placeholder placeholder-filled'>
        <CircularProgress style={{
          width: bounds.width / 4,
          height: bounds.height / 4,
          color: 'black'
        }} variant='indeterminate'/>
      </div> }
    </React.Fragment>
  )
}

export default Miniature
